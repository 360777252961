<template>
    <div class="app-body">
        <bread-crumb></bread-crumb>
        <el-card class="el-main">
            <el-form :model="form" ref="form" :rules="rules" label-width="145px" label-position="left">
                <el-form-item label="电费项目名称" prop="name" >
                    <el-input v-model="form.name" maxlength="24" style="width:240px" placeholder="请输入名称"></el-input>
                </el-form-item>
                <el-form-item label="电费价格" prop="unitPrice" >
                    <div class="a-flex-rfsc">
                        <jd-input-price v-model="form.unitPrice" style="width:240px"></jd-input-price>
                        <span class="a-ml-10">元/kwh</span>
                    </div>
                </el-form-item>
                <el-form-item label="电费收取方式" prop="chargeFeeType" >
                    <el-select v-model="form.chargeFeeType" placeholder="请选择" clearable @change='chargeFeeTypeChange'>
                        <el-option v-for="item in chargeFeeTypeDic"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="电费收款方" prop="chargePayeeType" >
                    <el-select v-model="form.chargePayeeType" placeholder="请选择" clearable :disabled='form.chargeFeeType == 2' @change='chargePayeeTypeChange'>
                        <el-option v-for="item in chargePayeeTypeDic"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="收款物业方" prop="propertyList" label-width='125px' v-if="form.chargePayeeType == 2">
                    <div class="a-flex-rfsc a-mb-05" v-for="(item,index) in form.propertyList" :key="index">
                        <le-select-remote-search 
                            style="padding: 0 !important"
                            v-model="item.propertyId" 
                            :options="optionsProperty" 
                            :defaultOptions='defaultPropertyList'
                            placeholder="请选择（可输入搜索）" />
                        <span class="a-mlr-12">，物业占比：</span>
                        <el-input v-model="item.rate" maxlength="24" style="width:120px" placeholder="请输入">
                            <template slot="suffix">%</template>
                        </el-input>
                        <el-tooltip class="devList-btn" effect="dark" content="删除" placement="top" v-if="form.propertyList.length > 1">
                            <img src="../../assets/icon/option-del-circle.png" @click="delProperty(index)" class="a-wh-16 a-mlr-12" />
                        </el-tooltip>
                        <el-tooltip class="devList-btn" effect="dark" content="添加" placement="top" >
                            <img src="../../assets/icon/option-add-circle.png" @click="addProperty" class="a-wh-16 a-mlr-12" />
                        </el-tooltip>
                    </div>
                </el-form-item>
                <div class="a-fs-14 a-fw-700 a-flex-rfsc" style="margin: 36px 0 16px">
                    <span>关联站点</span>
                </div>
                <div class="a-flex-rfsc">
                    <el-button icon="el-icon-plus" type="primary"  @click="addElectricityMeter">添加电表号</el-button>
                    <!-- <el-button icon="el-icon-plus" type="primary"  @click="addStationItem">添加列表</el-button> -->
                </div>
                <el-form-item prop="stationAmmeterList" label-width='0'>
                    <el-table :border='true' :data="form.stationAmmeterList" style="width: 1200px;margin-top: 16px">
                        <el-table-column label="站点(可多选)" >
                            <template slot-scope="{ row }">
                                <le-select-remote-search 
                                    :multiple='true' 
                                    reserveKeyword 
                                    v-model="row.stationIdList" 
                                    :options="optionsStation" 
                                    :defaultOptions='row.defaultStationList'
                                    :disableOptions='disableStationList'
                                    placeholder="请选择（可输入搜索）" />
                            </template>
                        </el-table-column>
                        <el-table-column label="电表号(可多选)" >
                            <template slot-scope="{ row }">
                                <le-select-remote-search 
                                    :multiple='true' 
                                    isObj
                                    reserveKeyword 
                                    requestStart
                                    v-model="row.ammeterIdList" 
                                    :options="optionsAmmeter" 
                                    :defaultOptions='defaultAmmeterAllList.concat(row.defaultAmmeterList)'
                                    :disableOptions='disableAmmeterList'
                                    placeholder="请选择（可输入搜索）" />
                            </template>
                        </el-table-column>
                        <el-table-column label="电表设备占比" >
                            <template slot-scope="{ row }">
                                <div class="a-flex-cfsfs a-w-100">
                                    <div class="a-flex-rfsc  a-w-100" v-for="(item, index) in row.ammeterIdList" :key="index">
                                        <span class="block ammeterNo">No.{{ item.name || item.label }}：</span>
                                        <el-input  
                                            :disabled='row.ammeterIdList.length <= 1'
                                            style="margin-bottom: 6px"
                                            v-model="item.ratio" 
                                            maxlength="50" 
                                            @input="item.ratio = util.checkMoney(item.ratio)" 
                                            placeholder="请输入" >
                                            <template slot="append">%</template>
                                        </el-input>
                                    </div>
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column prop="name" label="操作" width="100" fixed="right">
                            <template slot-scope="scope">
                                <el-tooltip class="item" effect="dark" content="删除" placement="top">
                                    <img src="../../assets/icon/option-del-circle.png" class="a-wh-16" @click="delStationItem(scope.$index)" v-if="form.stationAmmeterList.length > 1" />
                                </el-tooltip>
                                <el-tooltip class="item a-ml-12" effect="dark" content="添加列表" placement="top">
                                    <img src="../../assets/icon/option-add-circle.png" class="a-wh-16" v-if="scope.$index + 1 == form.stationAmmeterList.length" @click="addStationItem" />
                                </el-tooltip>
                            </template>
                        </el-table-column>
                    </el-table>
                </el-form-item>
                
                <div style="height: 100px;"></div>
            </el-form>
            <div class="a-line-t-e0 footerBox">
                <el-button type="primary" class="a-ml-24 a-mt-15" @click="submit">立即提交</el-button>
                <el-button class="a-ml-24 a-mt-15" style="margin-left: 24px" @click="cancelSubmit">返回</el-button>
            </div>
        </el-card>
    </div>
</template>

<script>
import util from '@/utils/util'
export default {
    data () {
        var checkPropertyList = async (rule, value, callback) => {
            let errorText = await this.checkRules(this.form.propertyList)
            if(errorText){
                callback(new Error(errorText))
            }else{
                callback()
            }
        };
        var checkStationList = async (rule, value, callback) => {
            let errorText = await this.checkRulesStation(this.form.stationAmmeterList)
            if(errorText){
                callback(new Error(errorText))
            }else{
                callback()
            }
        };
        
        return {
            util: util,
            id: '',
            editType: '',
            form: {
                name: '',
                chargeFeeType: '',//电费收取方式:1:预充值,2先用后付
                chargePayeeType: '',//电费收款方类型:1:App自交,2物业代收
                unitPrice: '',//电费单价
                propertyList: [{
                    propertyId: '',
                    rate: 100
                }],
                stationAmmeterList: [{
                    stationIdList: [],
                    ammeterIdList: [],
                    defaultStationList: [],
                    defaultAmmeterList: [],
                }]
            },
            rules: {
                name: [{required: true, message:'请填写电费项目名称', trigger: 'blur'}],
                chargeFeeType: [{required: true, message:'请选择电费收取方式', trigger: 'change'}],//电费收取方式:1:预充值,2先用后付
                chargePayeeType: [{required: true, message:'请选择电费收取方', trigger: 'change'}],//电费收款方类型:1:App自交,2物业代收
                unitPrice: [{required: true, message:'请填写电费单价', trigger: 'blur'}],//电费单价
                propertyList: [{ validator: checkPropertyList, trigger: 'change' }],
                stationAmmeterList: [{ validator: checkStationList, trigger: 'change' }]
            },
            optionsStation: {
                url: this.$Config.apiUrl.electricStationFilter,
                method: "post",
                params: {},
                showLoading: false,
                labelKey: "name",
                valueKey: "id",
                searchKey: "name",
                pageSize: 500
            },//站点数据
            optionsAmmeter: {
                url: this.$Config.apiUrl.ammeterFilter,
                method: "post",
                params: {
                    isUsed: 0
                },
                showLoading: false,
                labelKey: "name",
                valueKey: "id",
                searchKey: "name",
                pageSize: 500
            },
            optionsProperty: {
                url: this.$Config.apiUrl.propertyCompanyfilter,
                method: "post",
                params: {},
                showLoading: false,
                labelKey: "name",
                valueKey: "id",
                searchKey: "name",
                pageSize: 500
            },
            defaultPropertyList: [],
            chargeFeeTypeDic: [],
            chargePayeeTypeDic: [],
            disableAmmeterList: [],//不可选择的电表集合
            disableStationList: [],//不可选择的站点集合
            defaultAmmeterAllList: [],//该项目绑定的所有电表集合
            defaultAmmeterAllListReadOnly: []
        }
    },
    watch: {
        'form.stationAmmeterList': {
            deep: true,
            handler (val) {
                let disableAmmeterList = [], disableStationList = []
                this.form.stationAmmeterList.map(item=>{
                    disableStationList = item.stationIdList.concat(disableStationList)
                    disableAmmeterList = item.ammeterIdList.concat(disableAmmeterList)
                })
                this.disableAmmeterList = disableAmmeterList.map(item=>{
                    return item.id || item.value
                })
                this.disableStationList = disableStationList.map(item=>{
                    return item.id || item.value
                })
                // 将所有默认选项中去除掉已被选中的数据 作为默认选项列表
                let defaultAmmeterAllList = this.defaultAmmeterAllListReadOnly.filter(res=>{
                    return this.disableAmmeterList.indexOf(res.id) == -1
                })
                this.defaultAmmeterAllList = defaultAmmeterAllList
            }
        }
    },
    mounted () {
        this.id = this.$route.query.id
        this.editType = this.$route.query.type
        if(this.id) {
            this.getPropertyDetail()
        }
        this.$getDic('chargeFeeType','select').then(res=>{ this.chargeFeeTypeDic = res; })
        this.$getDic('chargePayeeType','select').then(res=>{ this.chargePayeeTypeDic = res; })
    },
    methods:{
        // 删除物业收款方
        delProperty (index) {
            this.form.propertyList.splice(index,1)
            this.$refs['form'].validateField(['propertyList'])
        },
        // 删除关联站点列表项
        delStationItem (index) {
            this.form.stationAmmeterList.splice(index,1)
        },
        // 添加站点列表
        addStationItem () {
            this.form.stationAmmeterList.push({
                stationIdList: [],
                ammeterIdList: [],
                defaultStationList: [],
                defaultAmmeterList: [],
            })
        },
        // 添加物业收款方
        addProperty () {
            this.form.propertyList.push({
                propertyId: '',
                rate: ''
            })
        },
        // 添加电表号
        addElectricityMeter () {
            this.$prompt('请输入电表号', '温馨提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
            }).then(({ value }) => {
                this.$Axios._post({
                    url: this.$Config.apiUrl.ammeterAdd,
                    params: { 
                        meterNumber: value
                    }
                }).then(res => {
                    if (res.result.code == 0) {
                        this.$message.success('操作成功')
                    }
                })
            }).catch(() => {
   
            });
        },
        // 电费收取方式更改
        chargeFeeTypeChange () {
            if(this.form.chargeFeeType == 2) {
                this.form.chargePayeeType = '2'
            }
        },
        // 电费收款方修改
        chargePayeeTypeChange () {
            this.form.propertyList = [{
                propertyId: '',
                rate: 100
            }]
        },
        getPropertyDetail () {
            this.$Axios._post({
                url: this.$Config.apiUrl.electricInfo,
                method: "post",
                params: {
                    id: this.id
                }
            })
            .then(res=>{
                if (res.result.code == 0) {
                    this.form = {
                        ...res.result.data,
                        chargeFeeType: JSON.stringify(res.result.data.chargeFeeType),//电费收取方式:1:预充值,2先用后付
                        chargePayeeType: JSON.stringify(res.result.data.chargePayeeType),//电费收款方类型:1:App自交,2物业代收
                        unitPrice: Number((res.result.data.unitPrice/100).toFixed(2)),//电费单价
                    }
                    this.form.propertyList = this.form.propertyList.map(item=>{
                        return {
                            ...item,
                            rate: Number((item.rate/100).toFixed(2))
                        }
                    })
                    this.defaultAmmeterAllListReadOnly = this.form.stationAmmeterList.map(item=>{
                        return {
                            id: item.ammeterId,
                            name: item.meterNumber,
                        }
                    })
                    this.defaultAmmeterAllListReadOnly = this.uniqueArr(this.defaultAmmeterAllListReadOnly)

                    let stationGroup = this.groupById(this.form.stationAmmeterList)
                    let arr = Object.values(stationGroup)
                    this.form.stationAmmeterList = arr.map(item=>{
                        let stationIdList = []
                        let ammeterIdList = []
                        let defaultStationList = []
                        let defaultAmmeterList = []
                        item.map(ite=>{
                            stationIdList.push(ite.stationId)
                            ammeterIdList.push({
                                id: ite.ammeterId,
                                name: ite.meterNumber,
                                value: ite.ammeterId,
                                label: ite.meterNumber,
                                ratio: Number((ite.ratio * 100).toFixed(2))
                            })
                            defaultStationList.push({
                                name: ite.stationName,
                                id: ite.stationId
                            })
                            defaultAmmeterList.push({
                                name: ite.meterNumber,
                                id: ite.ammeterId
                            })
                        })
                        return {
                            stationIdList: [...new Set(stationIdList)],
                            // ammeterIdList: [...new Set(ammeterIdList)],
                            ammeterIdList: this.uniqueArr(ammeterIdList),
                            defaultStationList: this.uniqueArr(defaultStationList),
                            defaultAmmeterList: this.uniqueArr(defaultAmmeterList)
                        }
                    })
                } else {
                    this.$message.error(res.result.message)
                }
            })
            .catch(err=>{
                console.log(err);
            })
        },
        submit () {
            this.$refs['form'].validate((valid) => {
                if (valid) {
                    let reqData = {
                        ...this.form
                    }
                    let stationAndAmmeterList = []
                    reqData.stationAmmeterList.map((item,index)=>{
                        item.stationIdList.map(ite=>{
                            item.ammeterIdList.map(it=>{
                                stationAndAmmeterList.push({
                                    ammeterId: it.id || it.value,
                                    ratio: it.ratio ? Number((it.ratio * 100).toFixed(0)) : 10000,
                                    stationId: ite,
                                    groupId: index+1,//组id，传给后端，标明哪些数据为一组
                                })
                            })
                        })
                    })
                    reqData.propertyList = reqData.propertyList.map(item=>{
                        return {
                            ...item,
                            rate: Number((item.rate*100).toFixed(0))
                        }
                    })
                    this.$confirm('是否确认提交？','温馨提示')
                    .then(_=>{
                        this.$Axios._post({
                            url: this.$Config.apiUrl.electricEdit,
                            method: "post",
                            params: {
                                ...reqData,
                                unitPrice: reqData.unitPrice?Number((reqData.unitPrice*100).toFixed(0)):0,
                                stationAmmeterList: stationAndAmmeterList
                            }
                        })
                        .then(res=>{
                            if (res.result.code == 0) {
                                this.$message.success('操作成功')
                                this.$router.back()
                                this.$store.dispatch("delView", this.$route);
                            } else {
                                this.$message.error(res.result.message)
                            }
                        })
                        .catch(err=>{

                        })
                    })
                    .catch(_=>{})
                }else{
                    this.$message.error('请检查所填参数是否正确')
                }
            })
        },
        cancelSubmit () {
            this.$confirm('是否确认返回？','温馨提示')
            .then(_=>{
                this.$router.back()
                this.$store.dispatch("delView", this.$route);
            })
            .catch(_=>{})
        },
        checkRules (datas) {
            return new Promise((resolve, reject) => {
                if(datas.length && datas){
                    let rateAll = 0
                    let propertyId = []
                    for (let index = 0; index < datas.length; index++) {
                        const item = datas[index];
                        if(!item.propertyId){
                            resolve('请选择物业')
                            return
                        }
                        if(!item.rate || item.rate == 0 || item.rate == '0'){
                            resolve('请填写物业收款占比')
                            return
                        }
                        rateAll += Number(item.rate)
                        propertyId.push(item.propertyId)
                    }
                    if(new Set(propertyId).size !== propertyId.length) {
                        resolve('不可重复选择物业收款方')
                    }else if(rateAll != 100) {
                        resolve('物业收款占比之和必须等于100%')
                    }else {
                        resolve()
                    }
                }else{
                    resolve()
                }
            })
        },
        checkRulesStation (datas) {
            return new Promise((resolve, reject) => {
                if(datas.length && datas){
                    for (let index = 0; index < datas.length; index++) {
                        const item = datas[index];
                        if(!item.stationIdList.length){
                            resolve('请选择站点')
                            return
                        }
                        if(!item.ammeterIdList.length){
                            resolve('请选择电表')
                            return
                        }
                        let ratioAll = 0
                        item.ammeterIdList.map(ite=>{
                            ratioAll += Number(ite.ratio || 100)
                        })
                        if(ratioAll != 100) {
                            resolve('每个电表的设备占比之和必须等于100%')
                            return
                        }
                    }
                    resolve()
                }else{
                    resolve()
                }
            })
        },
        groupById(arr) {
            const result = {};
            arr.forEach(item => {
                if (!result[item.groupId]) {
                result[item.groupId] = [];
                }
                result[item.groupId].push(item);
            });
            return result;
        },
        uniqueArr (arr) {
            return [...new Set(arr.map(t=>JSON.stringify(t)))].map(s=>JSON.parse(s))
        },
    },
}
</script>

<style lang="scss" scoped>
.send-user{
    background: #53A8FF;
    color: #ffffff;
    border-radius: 4px;
    padding: 0 10px;
    margin: 0 10px 10px 0;
}
.mt64{
    margin-top: 64px
}
.mt21{
    margin-top: 12px
}
.mr80{
    margin-right: 80px;
}
.c79{
    color: #797979
}
.title{
    width: 150px;
    margin-right: 16px;
}
.content{
    width: 240px
}
.tips{
    font-size: 12px;
    color: #909399;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    line-height: 22px;
}
.ammeterNo{
    white-space: nowrap;
}
/deep/ .el-input-group__prepend{
    height: 32px;
    line-height: 32px;
}
/deep/ .el-input-group__append{
    height: 32px;
    line-height: 32px;
}
</style>